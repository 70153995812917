<template>
  <g>
    <g clip-path="url(#clip0_18_21)">
      <path
        d="M96 17.7139V29.9387H89.3982V19.4354C89.4075 18.0437 88.8753 16.7018 87.912 15.688C86.9486 14.6741 85.628 14.0661 84.2239 13.9899H83.972C82.5219 13.9904 81.1138 14.4726 79.9734 15.3592V29.9545H73.2716V8.18812H79.9254V9.13397C82.9597 7.98652 86.2951 7.87922 89.3982 8.82924L89.4742 8.85298C91.3744 9.45837 93.0309 10.6462 94.204 12.2448C95.3771 13.8434 96.0061 15.7698 96 17.7456"
        fill="white"
      />
      <g opacity="0.7">
        <path
          opacity="0.7"
          d="M45.6528 32.8475C45.4344 33.223 45.0913 33.5119 44.6818 33.665C44.2722 33.8181 43.8217 33.8259 43.4071 33.687C42.9924 33.5481 42.6393 33.2712 42.4079 32.9034C42.1765 32.5356 42.0812 32.0998 42.1382 31.6702C42.1951 31.2406 42.4009 30.8439 42.7204 30.5478C43.0399 30.2516 43.4533 30.0742 43.8901 30.0459C44.3269 30.0177 44.7601 30.1403 45.1158 30.3928C45.4715 30.6453 45.7277 31.0121 45.8407 31.4307C45.9703 31.9089 45.9027 32.4185 45.6528 32.8475"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M71.3602 7.45599C71.1399 7.82767 70.7963 8.11235 70.3879 8.26157C69.9795 8.4108 69.5314 8.41536 69.1199 8.27449C68.7085 8.13361 68.359 7.85599 68.1311 7.48887C67.9031 7.12174 67.8107 6.68779 67.8696 6.26084C67.9285 5.83389 68.135 5.44032 68.454 5.14709C68.7731 4.85386 69.1849 4.67907 69.6194 4.65248C70.054 4.62589 70.4844 4.74912 70.8375 5.00122C71.1906 5.25333 71.4446 5.61872 71.5561 6.03523C71.6199 6.27313 71.6354 6.52115 71.6017 6.765C71.5681 7.00885 71.486 7.2437 71.3602 7.45599"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M43.0817 8.14854C42.7026 7.93226 42.411 7.59257 42.2566 7.18737C42.1022 6.78216 42.0945 6.3365 42.2348 5.92632C42.3752 5.51614 42.6549 5.16681 43.0263 4.93786C43.3978 4.70891 43.8379 4.6145 44.2718 4.67072C44.7056 4.72694 45.1064 4.9303 45.4058 5.24617C45.7051 5.56203 45.8845 5.97085 45.9135 6.40297C45.9424 6.83509 45.8191 7.26377 45.5645 7.61598C45.3099 7.96819 44.9398 8.22213 44.5172 8.33454C44.2773 8.39836 44.027 8.41478 43.7807 8.38286C43.5344 8.35095 43.2969 8.27133 43.0817 8.14854"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M68.7771 33.5242C68.4534 33.3384 68.1926 33.0617 68.0279 32.7291C67.8631 32.3965 67.8017 32.0231 67.8515 31.656C67.9013 31.2889 68.06 30.9446 68.3075 30.6669C68.5551 30.3891 68.8803 30.1902 69.2421 30.0954C69.6039 30.0006 69.9859 30.0142 70.3399 30.1344C70.6939 30.2546 71.0039 30.4761 71.2307 30.7707C71.4574 31.0653 71.5908 31.4199 71.6139 31.7896C71.6369 32.1593 71.5487 32.5275 71.3602 32.8475C71.1072 33.2751 70.6936 33.5862 70.2096 33.713C69.7257 33.8398 69.2107 33.7719 68.7771 33.5242V33.5242Z"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M50.5392 2.81776C50.3514 2.49745 50.2638 2.12921 50.2875 1.75963C50.3112 1.39004 50.4451 1.03572 50.6724 0.741496C50.8996 0.447268 51.2099 0.226356 51.564 0.106708C51.9181 -0.0129396 52.3001 -0.0259452 52.6617 0.0693367C53.0233 0.164619 53.3482 0.363906 53.5953 0.641985C53.8424 0.920064 54.0006 1.26444 54.0499 1.63154C54.0992 1.99864 54.0374 2.37196 53.8723 2.70428C53.7072 3.0366 53.4462 3.31298 53.1223 3.49845V3.49845C52.6884 3.74531 52.1733 3.81186 51.6898 3.68353C51.2064 3.5552 50.7941 3.24245 50.5432 2.8138"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M59.94 37.4778C59.7229 37.1031 59.6436 36.6654 59.7158 36.2394C59.788 35.8134 60.0072 35.4253 60.336 35.1412C60.6648 34.8571 61.0829 34.6946 61.5192 34.6813C61.9554 34.668 62.3829 34.8048 62.7287 35.0684C63.0745 35.3319 63.3174 35.706 63.4159 36.1268C63.5144 36.5476 63.4625 36.9892 63.269 37.3765C63.0755 37.7637 62.7524 38.0725 62.3546 38.2505C61.9569 38.4284 61.5092 38.4644 61.0876 38.3524C60.8474 38.289 60.6223 38.179 60.4253 38.0289C60.2283 37.8788 60.0633 37.6914 59.94 37.4778"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M59.7241 1.87587C59.7233 1.50564 59.8335 1.14349 60.0407 0.835224C60.2478 0.526957 60.5427 0.286412 60.888 0.144009C61.2333 0.00160633 61.6135 -0.036259 61.9806 0.0352013C62.3476 0.106662 62.685 0.284238 62.9501 0.545476C63.2151 0.806713 63.396 1.13988 63.4697 1.50284C63.5435 1.8658 63.5068 2.24226 63.3644 2.5846C63.222 2.92695 62.9802 3.21981 62.6696 3.42615C62.359 3.63248 61.9936 3.74304 61.6195 3.74382C61.1193 3.74277 60.6398 3.54564 60.2861 3.19555C59.9324 2.84547 59.7332 2.37096 59.7321 1.87587"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M50.3113 36.5438C50.3097 36.1734 50.4192 35.8109 50.6259 35.5021C50.8326 35.1933 51.1272 34.9522 51.4724 34.8092C51.8176 34.6661 52.198 34.6277 52.5653 34.6987C52.9326 34.7697 53.2703 34.947 53.5358 35.2081C53.8013 35.4692 53.9825 35.8023 54.0566 36.1654C54.1307 36.5284 54.0943 36.9051 53.952 37.2477C53.8097 37.5903 53.5679 37.8833 53.2572 38.0899C52.9465 38.2964 52.5809 38.407 52.2067 38.4078V38.4078C51.9588 38.4083 51.7133 38.3605 51.4841 38.2671C51.2549 38.1738 51.0465 38.0366 50.8709 37.8635C50.6953 37.6904 50.5558 37.4848 50.4605 37.2584C50.3652 37.0319 50.3158 36.7891 50.3153 36.5438"
          fill="white"
        />
      </g>
      <path
        d="M56.877 11.0653C58.3809 11.0653 59.6001 9.85864 59.6001 8.37019C59.6001 6.88174 58.3809 5.67511 56.877 5.67511C55.3731 5.67511 54.1539 6.88174 54.1539 8.37019C54.1539 9.85864 55.3731 11.0653 56.877 11.0653Z"
        fill="white"
      />
      <path
        d="M70.4202 19.5226C70.6624 18.0535 69.6556 16.6683 68.1713 16.4285C66.687 16.1888 65.2874 17.1853 65.0451 18.6543C64.8028 20.1233 65.8097 21.5085 67.294 21.7483C68.7783 21.9881 70.1779 20.9916 70.4202 19.5226Z"
        fill="white"
      />
      <path
        d="M56.877 27.113C57.4156 27.113 57.9421 27.2711 58.3899 27.5672C58.8377 27.8634 59.1867 28.2843 59.3928 28.7768C59.5989 29.2692 59.6529 29.8111 59.5478 30.3339C59.4427 30.8567 59.1834 31.3369 58.8025 31.7138C58.4217 32.0907 57.9365 32.3474 57.4083 32.4514C56.88 32.5554 56.3325 32.502 55.8349 32.298C55.3374 32.0941 54.9121 31.7486 54.6129 31.3054C54.3136 30.8622 54.1539 30.3412 54.1539 29.8081C54.1539 29.0933 54.4408 28.4078 54.9515 27.9024C55.4622 27.397 56.1548 27.113 56.877 27.113V27.113Z"
        fill="white"
      />
      <path
        d="M46.0167 16.396C46.5553 16.396 47.0817 16.5541 47.5295 16.8502C47.9774 17.1464 48.3264 17.5673 48.5325 18.0597C48.7386 18.5522 48.7925 19.0941 48.6874 19.6169C48.5824 20.1397 48.323 20.6199 47.9422 20.9968C47.5614 21.3737 47.0762 21.6304 46.5479 21.7344C46.0197 21.8384 45.4722 21.785 44.9746 21.581C44.477 21.3771 44.0517 21.0316 43.7525 20.5884C43.4533 20.1452 43.2936 19.6241 43.2936 19.0911C43.2931 18.737 43.3631 18.3863 43.4998 18.0591C43.6365 17.7319 43.837 17.4346 44.09 17.1842C44.343 16.9339 44.6434 16.7354 44.974 16.6001C45.3046 16.4649 45.6589 16.3955 46.0167 16.396V16.396Z"
        fill="white"
      />
      <path
        d="M65.465 14.0464C66.8825 13.5493 67.6245 12.009 67.1223 10.606C66.62 9.20303 65.0637 8.46867 63.6461 8.96577C62.2285 9.46287 61.4865 11.0032 61.9888 12.4062C62.491 13.8092 64.0474 14.5435 65.465 14.0464Z"
        fill="white"
      />
      <path
        d="M64.5546 23.9747C65.0933 23.9739 65.6202 24.1313 66.0685 24.427C66.5168 24.7226 66.8665 25.1433 67.0732 25.6357C67.2799 26.1281 67.3344 26.6701 67.2298 27.1931C67.1252 27.7162 66.8662 28.1968 66.4855 28.5741C66.1048 28.9514 65.6196 29.2085 65.0913 29.3128C64.5629 29.4171 64.0152 29.364 63.5174 29.1601C63.0196 28.9562 62.5941 28.6108 62.2947 28.1675C61.9953 27.7242 61.8355 27.203 61.8355 26.6698C61.8349 26.316 61.9049 25.9657 62.0413 25.6387C62.1777 25.3118 62.3779 25.0146 62.6304 24.7643C62.883 24.514 63.1829 24.3154 63.5131 24.1799C63.8432 24.0444 64.1971 23.9747 64.5546 23.9747V23.9747Z"
        fill="white"
      />
      <path
        d="M49.1837 23.9747C49.7222 23.9747 50.2487 24.1328 50.6965 24.4289C51.1444 24.725 51.4934 25.146 51.6995 25.6384C51.9056 26.1309 51.9595 26.6728 51.8544 27.1956C51.7494 27.7184 51.49 28.1986 51.1092 28.5755C50.7284 28.9524 50.2431 29.2091 49.7149 29.3131C49.1867 29.4171 48.6392 29.3637 48.1416 29.1597C47.644 28.9557 47.2187 28.6103 46.9195 28.1671C46.6203 27.7239 46.4606 27.2028 46.4606 26.6698C46.4606 25.955 46.7475 25.2695 47.2581 24.7641C47.7688 24.2586 48.4615 23.9747 49.1837 23.9747V23.9747Z"
        fill="white"
      />
      <path
        d="M49.1837 8.81342C49.7222 8.81342 50.2487 8.97148 50.6965 9.26762C51.1444 9.56376 51.4934 9.98467 51.6995 10.4771C51.9056 10.9696 51.9595 11.5115 51.8544 12.0343C51.7494 12.5571 51.49 13.0373 51.1092 13.4142C50.7284 13.7911 50.2431 14.0478 49.7149 14.1518C49.1867 14.2558 48.6392 14.2024 48.1416 13.9984C47.644 13.7944 47.2187 13.449 46.9195 13.0058C46.6203 12.5626 46.4606 12.0415 46.4606 11.5085C46.4606 10.7937 46.7475 10.1082 47.2581 9.60279C47.7688 9.09736 48.4615 8.81342 49.1837 8.81342V8.81342Z"
        fill="white"
      />
      <path
        d="M56.889 14.3302C57.8368 14.3357 58.7618 14.6188 59.5472 15.144C60.3326 15.6691 60.9433 16.4127 61.3021 17.281C61.661 18.1492 61.7521 19.1032 61.5638 20.0226C61.3755 20.942 60.9163 21.7856 60.2441 22.447C59.572 23.1084 58.7169 23.5579 57.7869 23.739C56.8569 23.92 55.8935 23.8243 55.0183 23.4641C54.1432 23.1038 53.3954 22.4951 52.8694 21.7148C52.3434 20.9344 52.0626 20.0173 52.0626 19.0792C52.0679 17.8165 52.579 16.6074 53.4838 15.7172C54.3886 14.8269 55.6132 14.3281 56.889 14.3302Z"
        fill="white"
      />
      <path
        d="M32.7531 8.2198H39.079V13.9147H32.7531V21.7664C32.7481 22.1742 32.7749 22.5817 32.8331 22.9853C32.8821 23.324 33.0407 23.6378 33.285 23.8797C33.6101 24.1649 34.0087 24.3553 34.4366 24.4298C35.1538 24.5715 35.8847 24.6339 36.6158 24.6158C37.2436 24.6158 37.9034 24.5802 38.5872 24.5129C39.2143 24.457 39.8344 24.3403 40.4386 24.1647V30.101C39.8164 30.2479 39.1843 30.3497 38.5472 30.4057C37.8354 30.473 37.0957 30.5086 36.3279 30.5086C34.7315 30.5394 33.138 30.3597 31.5895 29.9743C30.4484 29.6956 29.3817 29.1758 28.4626 28.4507C27.6628 27.7858 26.1633 26.4719 26.1633 22.5738V2.33099H32.7531V8.2198Z"
        fill="white"
      />
      <path
        d="M11.0003 24.3546C9.96583 24.3546 8.95461 24.051 8.0945 23.4822C7.23439 22.9134 6.56402 22.105 6.16815 21.1591C5.77229 20.2132 5.66871 19.1724 5.87052 18.1683C6.07233 17.1642 6.57046 16.2418 7.30193 15.5179C8.03339 14.7939 8.96534 14.3009 9.97991 14.1012C10.9945 13.9015 12.0461 14.004 13.0018 14.3958C13.9575 14.7876 14.7744 15.451 15.3491 16.3023C15.9238 17.1536 16.2305 18.1544 16.2305 19.1782C16.2295 20.5507 15.6781 21.8668 14.6975 22.8373C13.7168 23.8079 12.3871 24.3536 11.0003 24.3546V24.3546ZM11.0003 8.29102C8.82428 8.28945 6.69669 8.92664 4.88664 10.122C3.0766 11.3173 1.66541 13.0171 0.831596 15.0064C-0.00221972 16.9956 -0.221204 19.1849 0.202342 21.2973C0.625889 23.4097 1.67294 25.3504 3.21104 26.8738C4.74915 28.3972 6.70921 29.4348 8.84328 29.8556C10.9774 30.2763 13.1896 30.0611 15.2001 29.2374C17.2106 28.4136 18.9291 27.0181 20.1382 25.2276C21.3473 23.437 21.9926 21.3318 21.9926 19.1782C21.9873 16.2955 20.8271 13.5326 18.7665 11.4954C16.7058 9.45814 13.9129 8.3129 11.0003 8.31081"
        fill="white"
      />
      <path
        d="M5.59818 40.9367H2.88708V36.2945H5.59818V37.086H3.88275V38.1031H5.48222V38.8946H3.88275V40.0818H5.59818V40.9367Z"
        fill="white"
      />
      <path
        d="M10.4605 38.5701C10.4819 38.8924 10.4342 39.2156 10.3205 39.5185C10.2068 39.8213 10.0297 40.0969 9.80075 40.3272C9.26919 40.7652 8.58745 40.9835 7.89738 40.9367H6.56982V36.2945H8.04133C8.69202 36.2543 9.33258 36.4689 9.82474 36.8921C10.0429 37.1125 10.2122 37.3757 10.3216 37.6646C10.4311 37.9536 10.4784 38.262 10.4605 38.5701ZM9.42887 38.5978C9.42887 37.6005 8.98102 37.1018 8.09332 37.1018H7.56149V40.1254H7.99735C8.9517 40.1254 9.42887 39.6162 9.42887 38.5978V38.5978Z"
        fill="white"
      />
      <path
        d="M15.2509 36.2945V39.2983C15.259 39.6144 15.1788 39.9267 15.019 40.2006C14.86 40.4605 14.6261 40.6672 14.3472 40.7942C14.0205 40.9387 13.6653 41.009 13.3076 41C12.7963 41.0311 12.2925 40.8668 11.9 40.5409C11.7311 40.3792 11.5991 40.1836 11.5129 39.9672C11.4267 39.7508 11.3883 39.5187 11.4002 39.2864V36.2945H12.3959V39.136C12.3717 39.4173 12.4497 39.6979 12.6158 39.9275C12.7101 40.0185 12.8235 40.0879 12.9478 40.131C13.0721 40.1741 13.2045 40.1897 13.3355 40.1768C13.4647 40.1887 13.595 40.1726 13.7173 40.1295C13.8395 40.0865 13.9508 40.0175 14.0433 39.9275C14.2094 39.6979 14.2873 39.4173 14.2632 39.136V36.2945H15.2509Z"
        fill="white"
      />
      <path
        d="M18.3938 37.0464C18.2269 37.0366 18.06 37.0675 17.908 37.1366C17.756 37.2056 17.6235 37.3105 17.5221 37.4422C17.2951 37.7957 17.1873 38.2114 17.2142 38.6295C17.2142 39.6637 17.6141 40.1808 18.4138 40.1808C18.8246 40.1647 19.2298 40.0818 19.6134 39.9354V40.7626C19.2031 40.9257 18.764 41.0064 18.3218 41C18.0317 41.0182 17.741 40.9727 17.4707 40.8666C17.2004 40.7606 16.9571 40.5966 16.7584 40.3866C16.3645 39.8842 16.1709 39.256 16.2145 38.6215C16.2042 38.1833 16.2959 37.7487 16.4825 37.3512C16.6465 37.0032 16.9127 36.7122 17.2462 36.5161C17.5961 36.3207 17.9921 36.221 18.3938 36.2272C18.8702 36.2328 19.3391 36.3451 19.7654 36.5557L19.4455 37.3472C19.2721 37.2656 19.0946 37.1929 18.9136 37.1295C18.7461 37.074 18.5705 37.0459 18.3938 37.0464V37.0464Z"
        fill="white"
      />
      <path
        d="M23.4601 40.9367L23.1202 39.8325H21.4088L21.0689 40.9367H19.9933L21.6487 36.2747H22.8483L24.5118 40.9367H23.4601ZM22.8803 39.0054C22.5684 38.0042 22.3885 37.4224 22.3485 37.3076C22.3085 37.1929 22.2805 37.0702 22.2645 36.995C22.1966 37.2681 21.9926 37.9369 21.6607 39.0054H22.8803Z"
        fill="white"
      />
      <path
        d="M26.9311 40.9367H25.9354V37.1137H24.6638V36.2945H28.2066V37.1137H26.9311V40.9367Z"
        fill="white"
      />
      <path
        d="M28.9264 40.9367V36.2945H29.9221V40.9367H28.9264Z"
        fill="white"
      />
      <path
        d="M35.3602 38.6096C35.3998 39.2457 35.1956 39.8731 34.7884 40.3668C34.3298 40.7645 33.7408 40.9837 33.131 40.9837C32.5212 40.9837 31.9322 40.7645 31.4735 40.3668C31.0606 39.8668 30.8548 39.2303 30.8977 38.5859C30.8524 37.9442 31.0602 37.3101 31.4775 36.8169C31.6947 36.6045 31.9549 36.4401 32.2409 36.3344C32.5269 36.2286 32.8323 36.184 33.137 36.2035C33.442 36.1862 33.7472 36.2338 34.032 36.343C34.3169 36.4522 34.5748 36.6205 34.7884 36.8367C35.1991 37.3346 35.4035 37.9683 35.3602 38.6096V38.6096ZM31.9374 38.6096C31.9069 39.0264 32.0105 39.442 32.2333 39.7969C32.3391 39.9297 32.4758 40.0351 32.6319 40.104C32.7879 40.1729 32.9585 40.2034 33.129 40.1927C33.9287 40.1927 34.3286 39.6742 34.3286 38.6096C34.3286 37.5451 33.9287 37.0266 33.129 37.0266C32.9585 37.0159 32.7879 37.0464 32.6319 37.1153C32.4758 37.1842 32.3391 37.2896 32.2333 37.4224C32.0086 37.7765 31.9049 38.1927 31.9374 38.6096Z"
        fill="white"
      />
      <path
        d="M40.4945 40.9367H39.2309L37.1876 37.4224H37.1596C37.1996 38.0424 37.2196 38.4856 37.2196 38.7521V40.9367H36.3319V36.2945H37.5875L39.6228 39.7692H39.6468C39.6148 39.1795 39.5868 38.7402 39.5868 38.4909V36.2945H40.4825L40.4945 40.9367Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_18_21">
        <rect width="96" height="41" fill="white" />
      </clipPath>
    </defs>
  </g>
</template>
